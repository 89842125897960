<template>
	<v-dialog v-model="model" persistent scrollable max-width="60vw" max-height="60vh" @close="$emit('close')">
		<v-card fill-height>
			<v-card-title class="secondary">
				<v-layout fluid align-center white--text>
					<v-flex v-if="!parentTask" v-t="'client_workspace.task.client_infos'" headline></v-flex>
					<v-flex v-else headline v-text="$t('tasks.import.vendor_name', { name: vendor.company })"></v-flex>
					<v-spacer></v-spacer>
					<v-btn icon @click="$emit('close')">
						<v-icon class="white--text">close</v-icon>
					</v-btn>
				</v-layout>
			</v-card-title>
			<v-card-text>
				<v-layout column fill-height overflow-hidden>
					<v-flex>
						<v-layout row justify-center align-center>
							<v-flex v-if="!parentTask" xs5>
								<v-combobox
									v-model="vendor"
									:label="$t('client_workspace.task.companies')"
									:items="companies"
									item-value="id"
									item-text="company"
									hide-details
									return-object
									prepend-icon="domain"
								/>
							</v-flex>
							<v-flex xs5 :offset-xs1="!parentTask" :offset-xs6="parentTask">
								<SelectWithChips
									v-model="themes"
									:disabled="!vendor"
									:label="$t('client_workspace.task.themes')"
									:items="themeList"
									:item-value="'id'"
									:item-text="'title'"
									multiple
									prepend-icon="group_work"
									:max-selected-items-showed="3"
								/>
							</v-flex>
						</v-layout>
					</v-flex>
					<v-flex v-if="!showTasksList">
						<v-layout column>
							<v-flex mt-4 mb-2>
								<v-badge color small right>
									<template v-slot:badge>
										<v-tooltip bottom>
											<template v-slot:activator="{ on }">
												<v-icon v-if="vendor" color="info" small class="pointer" v-on="on" @click="showExcelExample = true">help</v-icon>
											</template>
											<span v-t="'tasks.import.help_tooltip'" />
										</v-tooltip>
									</template>
									<v-flex v-text="vendor ? $t('tasks.import.help') : $t('tasks.import.select_vendor_first')" />
								</v-badge>
							</v-flex>
							<v-progress-linear v-if="pastedText && pastedText.length > 0" color="primary" indeterminate />
							<v-textarea v-model="pastedText" :disabled="!vendor" outline />
						</v-layout>
					</v-flex>
					<v-flex v-else-if="showTasksList" fill-height>
						<v-layout column fill-height>
							<v-flex>
								<v-layout row justify-end align-center>
									<v-btn color="primary" flat round outline small shrink @click="showImportedData = true">
										<v-icon small>table_chart</v-icon>
										<span v-t="'tasks.import.show_imported_data'" class="ml-2" />
									</v-btn>
									<v-btn color="primary" flat round outline small shrink @click="clear">
										<v-icon small>clear</v-icon>
										<span v-t="'tasks.import.clear_imported_data'" class="ml-2" />
									</v-btn>
								</v-layout>
							</v-flex>
							<v-flex scroll-y>
								<TasksManagerActionImportTasksListPreview :information-requests-list="informationRequestsList" />
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</v-card-text>
			<v-card-actions>
				<v-layout row justify-end align-center>
					<v-btn :disabled="!showTasksList" flat color="primary" @click="save">
						<v-icon>publish</v-icon>
						<span v-t="'import'" class="ml-2" />
					</v-btn>
					<v-btn flat color="primary" @click="$emit('close')">
						<v-icon>cancel</v-icon>
						<span v-t="'cancel'" class="ml-2" />
					</v-btn>
				</v-layout>
			</v-card-actions>
		</v-card>
		<TasksManagerActionImportExcelExample v-if="showExcelExample" :show="showExcelExample" @close="showExcelExample = false" />
		<TasksManagerActionImportedData v-if="showImportedData" :show-imported-data="showImportedData" :items="items" @close="showImportedData = false" />
	</v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import TasksManagerModuleGuard from '@/mixins/ModulesGuards/TasksManager/TasksManagerModuleGuard'

export default {
	name: 'TasksManagerActionImportDialog',
	components: {
		SelectWithChips: () => ({
			component: import('@/components/Commons/SelectWithChips')
		}),
		TasksManagerActionImportTasksListPreview: () => ({
			component: import('@/components/TasksManager/Actions/Import/TasksManagerActionImportTasksListPreview')
		}),
		TasksManagerActionImportExcelExample: () => ({
			component: import('@/components/TasksManager/Actions/Import/TasksManagerActionImportExcelExample')
		}),
		TasksManagerActionImportedData: () => ({
			component: import('@/components/TasksManager/Actions/Import/TasksManagerActionImportedData')
		})
	},
	mixins: [TasksManagerModuleGuard],
	props: {
		showImport: {
			required: true,
			type: Boolean
		},
		parentTask: {
			required: false,
			type: Object,
			default: null
		},
		company: {
			required: false,
			type: Object,
			default: null
		}
	},
	data: function () {
		return {
			showTasksList: false,
			showExcelExample: false,
			showImportedData: false,
			pastedText: null,
			items: [],
			vendor: this.company ? this.company : this.parentTask ? this.parentTask.vendor : null,
			themes: [],
			effectiveParentTask: this.parentTask,
			firstTaskStatus: null,
			accountingFirmTaskType: null,
			informationRequestType: null
		}
	},
	computed: {
		...mapState({
			companies: state => state.tasks.companies
		}),
		themeList: function () {
			return this.vendor ? this.vendor.themes : null
		},
		informationRequestsList: function () {
			let result = null
			if (this.items && this.items.length > 0) {
				result = this.items.map(item => {
					return {
						title: item.entry_title + ' du ' + this.$d(new Date(item.date), 'short') + ' pour ' + this.getSum(item) + '€',
						description: 'Journal ' + item.code + ' Numéro de compte: ' + item.account_title + ' Numéro de pièce: ' + item.reference,
						due_date: null,
						isChild: true,
						note: '',
						parent: this.effectiveParentTask,
						vendor: this.vendor,
						themes: this.themes,
						status: this.firstTaskStatus,
						type: this.informationRequestType
					}
				})
			}
			return result
		},
		model: {
			get: function () {
				return this.showImport
			},
			set: function () {
				// Do nothing
			}
		}
	},
	watch: {
		pastedText: {
			immediate: false,
			handler: function () {
				this.loadItemsFromPastedData()
			}
		},
		vendor: function () {
			if (this.vendor && this.vendor.themes && this.vendor.themes.length > 0) {
				this.themes = [...this.vendor.themes.map(theme => theme.id)]
				if (!this.parentTask && this.vendor) {
					this.service.findVendorInformationRequestHoldingTask(this.vendor.id).then(foundParentTask => {
						if (foundParentTask) {
							this.effectiveParentTask = foundParentTask
						} else {
							this.effectiveParentTask = {
								title: 'tasks.type.' + this.informationRequestType.name,
								vendor: this.vendor,
								themes: this.themes,
								type: this.accountingFirmTaskType
							}
						}
					})
				}
			} else {
				this.themes.clear()
			}
		}
	},
	created: function () {
		if (!this.vendor) {
			this.vendor = this.companies[0]
		}
	},
	mounted: function () {
		this.accountingFirmTaskType = this.service.getTaskAccountingFirmType()
		this.firstTaskStatus = this.service.getFirstTaskStatus()
		this.informationRequestType = this.service.getTaskInformationRequestType()
	},
	methods: {
		loadItemsFromPastedData: function () {
			if (this.pastedText && this.pastedText.length) {
				this.items = this.pastedText
					.split('\n')
					.filter(row => row && row.trim().length)
					.map(row => {
						const cells = row.split('\t')
						return {
							code: cells[0].trim(),
							date: cells[1].split('/')[2] + '-' + cells[1].split('/')[1] + '-' + cells[1].split('/')[0],
							account_title: cells[2].trim(),
							reference: cells[3].trim(),
							entry_title: cells[4].trim(),
							debit: parseFloat((cells[5].trim() === '' ? '0' : cells[5]).replace(',', '.')).toFixed(2),
							credit: parseFloat((cells[6].trim() === '' ? '0' : cells[6]).replace(',', '.')).toFixed(2)
						}
					})
				this.showTasksList = true
			}
		},
		getSum: function (item) {
			return (item.credit - item.debit).toFixed(2)
		},
		clear: function () {
			this.pastedText = null
			this.showTasksList = false
		},
		save: function () {
			this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, true)
			let result = Promise.resolve(this.effectiveParentTask)
			if (this.effectiveParentTask && !this.effectiveParentTask.id) {
				result = this.service.createTask(this.effectiveParentTask)
			}
			const promises = []
			result
				.then(parentTask => {
					this.informationRequestsList.forEach(informationRequest => {
						informationRequest.parent = parentTask
						promises.push(this.service.createTask(informationRequest))
					})
					this.service.selectTask(parentTask)
					return Promise.all(promises)
				})
				.then(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$tc('tasks.import.success', this.informationRequestsList.length))
				})
				.catch(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('tasks.import.error'))
				})
				.finally(() => {
					this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, false)
					this.$emit('close')
				})
		}
	}
}
</script>
